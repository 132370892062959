import type { AuthAction, PagesOptions } from 'next-auth';

// Read an env var dynamically, falling back to the potentially-inlined value.
const getEnv = (name: string, inlined?: string): string | undefined => process.env[name] || inlined;

// This corresponds to whereever we put the next-auth route handler:. /auth/[...nextauth]/route.ts
const basePath = `/auth`;

const publicUrl = new URL(
  getEnv('AUTH_URL', process.env['AUTH_URL']) ||
    getEnv('NEXTAUTH_URL', process.env['NEXTAUTH_URL']) ||
    `https://auth.invalid${basePath}`
);

const internalUrl = new URL(
  (typeof window === 'undefined'
    ? getEnv('AUTH_URL_INTERNAL', process.env['AUTH_URL_INTERNAL']) ||
      getEnv('NEXTAUTH_URL_INTERNAL', process.env['NEXTAUTH_URL_INTERNAL'])
    : void 0) || publicUrl
);

const url = typeof window === 'undefined' ? internalUrl : publicUrl;

// NOTE: the path must end with a slash, otherwise using it as the base URL will drop the path head component.
// NOTE: e.g. "https://foo.example/_a/_b/" is ok.
// but `new URL("foo/bar", "https://foo.example/_a/_b" )` will resolve to "https://foo.example/_a/foo/bar" without "_b".
url.pathname = url.pathname.replace(/\/*$/, '/');

export const authUrl = url.href;

// next-auth appends the auth path to this value.
export const authUrlOrigin = url.origin;

// next-auth just appends this value to the origin ("baseUrl") string, and then appends names like "/session" to it,
// as strings, so we have to remove the trailing slash.
// It constructs the URL like:
// > `${__NEXTAUTH.baseUrlServer}${__NEXTAUTH.basePathServer}/${path}`
// Where `baseUrlServer` is the origin, and `basePathServer` is the base path, and `path` is something like "session".
export const authUrlPath = url.pathname.replace(/\/*$/, '');

/**
 * [Bad Incorrect Documentation](https://next-auth.js.org/configuration/pages)
 *
 * The following route names are hard-coded in next-auth@4.24.7.
 *
 * They are not actually configurable; only the base of the URL is.
 * (With, I think, the exception of "pages.newUser", which never seems to be used literally.)
 */
const PageNames = {
  // "${base}/${'signin' | 'callback'}/${provider}"
  signIn: 'signin',
  callbacks: 'callback',

  signOut: 'signout',
  error: 'error',
  verifyRequest: 'verify-request',

  // This one, maybe, is configurable?
  newUser: 'new-user',
} as const satisfies PagesOptions & { callbacks: string };

PageNames satisfies Record<Exclude<keyof PagesOptions, 'newUser'> | 'callbacks', AuthAction>;

export type PageNames = typeof PageNames;

/**
 * Map of the page names to their absolute paths (not full URL).
 */
export const PagePaths = Object.freeze(
  Object.fromEntries(
    Object.entries(PageNames).map(([key, value]) => [key, new URL(`./${value}`, url).pathname] as const)
  )
) as { [K in keyof PageNames]: `${typeof basePath}/${PageNames[K]}` } satisfies Readonly<
  PagesOptions & { callbacks: string }
>;
