import { emitWarning } from 'process';
import { LogFormats, LogLevels } from './interface.js';
export const getDefaultLevelFromEnv = (env = process.env) => LogLevels.includes(env['FIDANT_LOG_LEVEL'])
    ? env['FIDANT_LOG_LEVEL']
    : env['NODE_ENV'] === 'development'
        ? 'debug'
        : 'info';
export const getDefaultFormatFromEnv = (env = process.env) => LogFormats.includes(env['FIDANT_LOG_FORMAT'])
    ? env['FIDANT_LOG_FORMAT']
    : env['NODE_ENV'] === 'development'
        ? 'inspect'
        : 'json';
export const getDefaultColorModeFromEnv = (env = process.env, isTTY = process.stdout.isTTY) => {
    if ('FIDANT_LOG_COLOR' in env) {
        const val = env['FIDANT_LOG_COLOR'];
        if (val === '1' || val === 'true') {
            return true;
        }
        else if (val === '0' || val === 'false' || val === '') {
            return false;
        }
        else if (val !== 'auto') {
            emitWarning(`FIDANT_LOG_COLOR must be 0, 1, true, false, or 'auto'`);
            return false;
        }
    }
    return isTTY;
};
export const getDefaultContextFromEnv = () => 
// True in browser context.
typeof process.pid === 'undefined'
    ? {}
    : {
        pid: process.pid,
    };
