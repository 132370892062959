import '@fidant-io/util/symbol-dispose-shim';
export const logContext = (_ctx) => {
    throw new Error('Not implemented');
};
export class AsyncLoggingScope {
    static get context() {
        return undefined;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static run(fn, ...args) {
        return Reflect.apply(fn, null, args);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static bind(fn) {
        return fn;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static wrap(fn) {
        return fn;
    }
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(_) { }
    [Symbol.dispose]() { }
}
