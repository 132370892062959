'use client';

import { useEffect, useState } from 'react';

/**
 * Wait until the client has rendered the page on the actual browser.
 *
 * @returns false during server-side rendering, true after the first client-side render.
 */
export const useIsHydrated = () => {
  const [hasRendered, setHasRendered] = useState(false);
  useEffect(() => {
    if (!hasRendered) {
      if (typeof window !== 'undefined') {
        setHasRendered(true);
      }
    }
  }, [hasRendered]);
  return hasRendered;
};
