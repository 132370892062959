/**
 * This is like 'makeFragmentData' but a little less finicky.
 * Useful in tests.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fragment(_fragment, data) {
    return data;
}
/**
 * Like `fragment`, but handles intersected spread fragments.
 */
export function fragments(_fragment, data
// ): Extract<ResultOf<Fs[number]>, FT> & FragmentType<UnionToIntersection<Fs[number]>> {
) {
    return data;
}
/**
 * Refine the type of a fragment, since otherwise fragment unions become unsatisfiable intersections.
 * This is a runtime no-op.
 */
export const fragmentWhere = (doc, constraint) => {
    void constraint; // satisfy unused vars lint
    return doc;
};
export const applyFragmentDataWithin = (fragmentDoc, data, path) => {
    void fragmentDoc;
    void path;
    return data;
};
export function getFragmentData(documentNode, fragmentType) {
    void documentNode;
    return fragmentType;
}
export function connectionNodes(connection, ...args) {
    const [_nodeFragmentDoc, fn] = args.length === 0 ? [undefined, undefined] : typeof args[0] === 'function' ? [undefined, args[0]] : args;
    const nodes = [];
    let i = 0;
    if (connection?.edges) {
        for (const edge of connection.edges) {
            const node = edge?.node ? (fn ? fn(edge.node, i) : edge.node) : undefined;
            if (node != null) {
                nodes.push(node);
            }
            ++i;
        }
    }
    return nodes;
}
/* eslint-enable @typescript-eslint/no-explicit-any */
