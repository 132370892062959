function sortedKeysProxy(o, compareFn) {
    // NOTE: this does not recursively proxy, because JSON.stringify will already call the replacer for all nested objects.
    if (typeof o !== 'object' || o === null || Array.isArray(o)) {
        return o;
    }
    const keys = Reflect.ownKeys(o);
    if (keys.length < 2) {
        return o;
    }
    keys.sort(compareFn);
    return new Proxy(o, { ownKeys: () => keys });
}
export function sortedStringify(value, compareFn) {
    return JSON.stringify(value, (_key, value) => sortedKeysProxy(value, compareFn), 0);
}
