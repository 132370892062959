import type { DependencyList } from 'react';
import { useRef } from 'react';

const areArraysShallowEqual = <T>(a: readonly T[], b: readonly T[]): boolean =>
  Object.is(a, b) || (a.length === b.length && a.every((el, i) => Object.is(el, b[i])));

export function useKeyedMemo<T, const D extends DependencyList>(
  factory: (...deps: D) => T,
  getKey: (...deps: D) => string,
  deps: D
): T {
  const ref = useRef<{ deps: D; key: string; value: T }>();
  if (!ref.current || !areArraysShallowEqual(ref.current.deps, deps)) {
    const key = getKey(...deps);
    if (!ref.current || key !== ref.current.key) {
      ref.current = { deps: [...deps] as D, key, value: factory(...deps) };
    }
  }
  return ref.current.value;
}

export function useKeyedMemoValue<T>(getKey: (value: T) => string, deps: [T]): T {
  return useKeyedMemo(value => value, getKey, deps);
}
