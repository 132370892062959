/**
 * A readonly view of a map that applies a function to the values.
 */
export class LazyMapView {
    data;
    fn;
    constructor(data, fn) {
        this.data = data;
        this.fn = fn;
    }
    *[Symbol.iterator]() {
        for (const [k, v] of this.data) {
            yield [k, this.fn(v)];
        }
    }
    get(id) {
        const v = this.data.get(id);
        return v ? this.fn(v) : undefined;
    }
    has(id) {
        return this.data.has(id);
    }
    *entries() {
        for (const [k, v] of this.data) {
            yield [k, this.fn(v)];
        }
    }
    keys() {
        return this.data.keys();
    }
    *values() {
        for (const v of this.data.values()) {
            yield this.fn(v);
        }
    }
    forEach(callbackfn, thisArg) {
        this.data.forEach((v, k) => void callbackfn.call(thisArg, this.fn(v), k, this));
    }
    get size() {
        return this.data.size;
    }
}
export default LazyMapView;
