// Remove this in node v21+
// https://github.com/nodejs/node/pull/48703/files
for (const name of ['dispose', 'asyncDispose']) {
    if (typeof Symbol[name] !== 'symbol') {
        Object.defineProperty(Symbol, name, {
            configurable: false,
            enumerable: false,
            value: Symbol.for(`nodejs.${name}`),
            writable: false,
        });
    }
}
export {};
