'use client';
import type { DependencyList } from 'react';
import { useRef } from 'react';

export function useCustomMemo<T, const D extends DependencyList>(
  factory: (...deps: D) => T,
  isEqual: (a: D, b: D) => boolean,
  deps: D
): T {
  const ref = useRef<{ deps: D; value: T }>();
  if (!ref.current || !isEqual(ref.current.deps, deps)) {
    ref.current = { deps: [...deps] as D, value: factory(...deps) };
  }
  return ref.current.value;
}
