import '@fidant-io/util/symbol-dispose-shim';
import LazyMapView from '@fidant-io/util/map/LazyMapView';
import { AdaptedLogger } from './adapter.js';
import { getDefaultContextFromEnv, getDefaultLevelFromEnv } from './config.js';
import { LogLevels } from './interface.js';
import { getLogLevel, LEVEL_NOT_CONFIGURED, LoggerModule } from './registry.js';
import { ADAPTED_LOGGER_REF_KEY, LOGGER_NAME } from './symbols.js';
class ConsoleLoggerRegistry {
    console;
    constructor(console) {
        this.console = console;
    }
    configure({ level = getDefaultLevelFromEnv(process.env), context: defaultMeta = getDefaultContextFromEnv(), } = {}) {
        this.configuration = { options: { level, defaultMeta } };
    }
    #configuration;
    // eslint-disable-next-line @typescript-eslint/related-getter-setter-pairs
    get configuration() {
        return this.#configuration;
    }
    set configuration(value) {
        this.#configuration = value;
    }
    #loggers = new Map();
    loggers = new LazyMapView(this.#loggers, v => ({
        ...(LogLevels.includes(v.level) ? { level: v.level } : {}),
        context: v.defaultMeta,
    }));
    // Get a logger, possibly before the platform configuration has been applied.
    // This sets overridden defaults if supplied, which are preserved in configure().
    get(id, specializedDefaults) {
        const w = this.#get(id, specializedDefaults);
        let logger = w[ADAPTED_LOGGER_REF_KEY]?.deref();
        if (logger) {
            return logger;
        }
        logger = new AdaptedLogger(new ConsoleLoggerAdapter(w));
        w[ADAPTED_LOGGER_REF_KEY] = new WeakRef(logger);
        return logger;
    }
    #get(id, specializedDefaults) {
        const logger = this.#loggers.get(id);
        if (!logger) {
            return this.define(id, specializedDefaults);
        }
        if (logger.level === LEVEL_NOT_CONFIGURED) {
            // This should only happen if we *don't* yet have a configuration.
            if (specializedDefaults?.level) {
                logger.level = specializedDefaults.level;
            }
            if (specializedDefaults?.context) {
                logger.defaultMeta = { ...logger.defaultMeta, ...specializedDefaults.context };
            }
        }
        return logger;
    }
    define(id, specializedOpts) {
        const globalOpts = this.configuration?.options ?? {};
        const logger = this.#loggers.get(id);
        const opts = {
            ...globalOpts,
            // If the logger already exists, reconfigure it but preserve the existing defaultMeta.
            // It doesn't exist, so the options given here will be used.
            console: logger?.console ?? this.console,
            // If the existing logger was created and given a level,
            // and we're not specifically overriding it, retain its current level.
            level: getLogLevel(globalOpts.level, logger?.level, specializedOpts?.level),
            enabledLevels: logger?.enabledLevels ?? new Set(LogLevels), // ?
            defaultMeta: {
                [LOGGER_NAME]: id,
                ...globalOpts.defaultMeta,
                // If the existing logger was created with some default context, let it take precedence over defaults.
                ...logger?.defaultMeta,
                ...specializedOpts?.context,
            },
        };
        if (logger) {
            Object.assign(logger, opts);
            return logger;
        }
        else {
            this.#loggers.set(id, opts);
            return opts;
        }
    }
}
const ConsoleSyslogLevelMap = {
    emerg: 'error',
    alert: 'warn',
    crit: 'error',
    error: 'error',
    warning: 'warn',
    notice: 'info',
    info: 'info',
    debug: 'debug',
};
class ConsoleLoggerAdapter {
    logger;
    #dynamicContextProvider;
    constructor(logger, dynamicContextProvider) {
        this.logger = logger;
        if (dynamicContextProvider) {
            this.#dynamicContextProvider = dynamicContextProvider;
        }
        this.logger = logger;
    }
    // eslint-disable-next-line @typescript-eslint/related-getter-setter-pairs
    get name() {
        return this.logger.defaultMeta[LOGGER_NAME];
    }
    set name(name) {
        this.logger.defaultMeta[LOGGER_NAME] = name;
    }
    get level() {
        const level = this.logger.level;
        if (level === LEVEL_NOT_CONFIGURED) {
            return 'emerg';
        }
        return this.logger.level;
    }
    set level(level) {
        this.logger.level = level;
    }
    isEnabledFor(level) {
        return !this.logger.isClosed && !this.logger.silent && this.logger.enabledLevels.has(level);
    }
    log(level, message, ...splat) {
        if (!this.isEnabledFor(level)) {
            return;
        }
        const entry = {
            ...this.#dynamicContextProvider?.(),
            ...this.logger.defaultMeta,
            level,
            ...(typeof message === 'string'
                ? {
                    message,
                    splat,
                }
                : message instanceof Error
                    ? {
                        message: message.message,
                        splat: [message, ...splat],
                    }
                    : {
                        message: '*',
                        splat: [message, ...splat],
                    }),
        };
        try {
            this.logger.console[ConsoleSyslogLevelMap[level]](entry);
        }
        catch (e) {
            this.logger.console.error('Failed to log', { level, message, msg: splat, error: e });
        }
    }
    child(context) {
        const childLogger = {
            console: this.logger.console,
            defaultMeta: { ...this.logger.defaultMeta, ...context },
            level: this.logger.level,
            enabledLevels: new Set(this.logger.enabledLevels),
            silent: this.logger.silent,
        };
        return new this.constructor(childLogger, this.#dynamicContextProvider);
    }
    [Symbol.dispose]() {
        this.logger.isClosed = true;
    }
}
export const GlobalLoggerModule = new LoggerModule(new ConsoleLoggerRegistry(globalThis.console));
