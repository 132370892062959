import '@fidant-io/util/symbol-dispose-shim';
import { BlockTimer } from './block-timer.js';
import { LogLevelAliases, LogLevels } from './interface.js';
export const newLogLevelAccessor = (transport, level) => {
    function log(message, ...args) {
        transport.deref()?.log(level, message, ...args);
    }
    const isEnabled = () => transport.deref()?.isEnabledFor(level) ?? false;
    const time = function time(arg, ...etc) {
        if (!isEnabled()) {
            return;
        }
        return new BlockTimer(typeof arg === 'string'
            ? (dt) => void log(arg, { dt }, ...etc)
            : (dt) => {
                const res = arg(dt);
                const { message, ...data } = typeof res === 'string' ? { message: res } : res;
                return void log(message, { dt, ...data }, ...etc);
            });
    };
    Object.defineProperties(log, {
        name: { value: level, writable: false },
        level: { value: level, writable: false },
        time: { value: time, writable: false },
        isEnabled: { get: isEnabled },
    });
    return log;
};
export class AdaptedLogger {
    #adapter;
    constructor(adapter) {
        this.#adapter = adapter;
        const transport = new WeakRef(adapter);
        for (const level of LogLevels) {
            this[level] = newLogLevelAccessor(transport, level);
        }
        for (const [alias, level] of Object.entries(LogLevelAliases)) {
            this[alias] = this[level];
        }
    }
    child(context) {
        return new this.constructor(this.#adapter.child(context));
    }
    isLevelEnabled(level) {
        return this.#adapter.isEnabledFor(level);
    }
    set level(level) {
        this.#adapter.level = level;
    }
    get level() {
        return this.#adapter.level;
    }
    set name(name) {
        this.#adapter.name = name;
    }
    get name() {
        return this.#adapter.name;
    }
    [Symbol.dispose]() {
        this.#adapter[Symbol.dispose]();
    }
    debug;
    info;
    notice;
    warn;
    warning;
    error;
    crit;
    alert;
    emerg;
}
