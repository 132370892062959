import { useMemo } from 'react';

import { useCustomMemo } from '@/components/useCustomMemo';
import { useLocalFlag } from '@/flag';
import type { LoggerContext, LogLevel } from '@/log';
import { getLogger, LogLevels } from '@/log';
import { sortedStringify } from '@fidant-io/util/sorted-json';

/**
 * Parse the localStorage key `logger["@fidant-io/whatever"].level` as a log level.
 */
export function useConfiguredLogLevel(name: string, defaultLevel: LogLevel | null) {
  // Parse '' or 'off' as 'off'; otherwise a valid level.
  // `null` means "no (valid) value, so use default".
  const customLogLevel = useLocalFlag(`logger[${JSON.stringify(name)}].level`, val =>
    val === '' || val === 'off' ? ('off' as const) : LogLevels.includes(val as LogLevel) ? (val as LogLevel) : null
  );

  const logLevel = !customLogLevel ? defaultLevel : customLogLevel !== 'off' ? customLogLevel : null;
  return logLevel;
}

export function useLogger(name: string, defaultLevel: LogLevel | null = null, context?: LoggerContext) {
  // Memoize context by JSON equality.
  context = useCustomMemo(
    ctx => ctx,
    ([a], [b]) => a === b || sortedStringify(a) === sortedStringify(b),
    [context]
  );

  // The base logger is part of a global registry, so we always take a child logger in order to define
  // its level and context.
  const baseLogger = useMemo(() => getLogger(name), [name]);
  const level = useConfiguredLogLevel(name, defaultLevel);
  const logger = useMemo(() => {
    if (!level) {
      return null;
    }
    const logger = baseLogger.child(context);
    logger.level = level;
    return logger;
  }, [level, baseLogger, context]);
  return logger;
}
