class PromiseWithResolversAndResultImpl {
    #pwt;
    #result;
    constructor() {
        this.#pwt = Promise.withResolvers();
    }
    get promise() {
        return this.#pwt.promise;
    }
    get result() {
        return this.#result;
    }
    resolve(value) {
        if (!this.#result) {
            this.#result = { status: 'fulfilled', value };
            this.#pwt.resolve(value);
        }
    }
    reject(reason) {
        if (!this.#result) {
            this.#result = { status: 'rejected', reason };
            this.#pwt.reject(reason);
        }
    }
}
export const promiseWithResolversAndResult = () => new PromiseWithResolversAndResultImpl();
