import type { ReadonlyHeaders } from 'next/dist/server/web/spec-extension/adapters/headers';

export function getForwardingRequestHeaders(
  userAgent: string,
  rawHeaders: ReadonlyHeaders,
  cookies?: string | null
): HeadersInit;
export function getForwardingRequestHeaders(
  userAgent: string,
  rawHeaders?: ReadonlyHeaders | null,
  cookies?: string | null
): HeadersInit | null;
export function getForwardingRequestHeaders(
  userAgent: string,
  rawHeaders?: ReadonlyHeaders | null,
  cookies?: string | null
): HeadersInit | null {
  return !rawHeaders && !cookies
    ? null
    : ([
        ['user-agent', `${userAgent} ${rawHeaders?.get('user-agent') ?? 'unknown'}`] satisfies [string, string],
        ...(!rawHeaders
          ? []
          : Array.from(rawHeaders.entries()).filter(
              ([k, _v]) =>
                ['authorization', 'accept-language', 'x-request-id', 'x-real-ip'].includes(k) ||
                (k === 'cookie' && !cookies) ||
                [/^x-forwarded-/, /^x-fidant-/].some(re => re.test(k))
            )),
        ...(cookies ? [['cookie', cookies] satisfies [string, string]] : []),
      ] satisfies HeadersInit);
}
