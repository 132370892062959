'use client';

import type { ReadonlyHeaders } from 'next/dist/server/web/spec-extension/adapters/headers';
import type { ReadonlyRequestCookies } from 'next/dist/server/web/spec-extension/adapters/request-cookies';
import { use, useMemo } from 'react';

import { getForwardingRequestHeaders } from './headers';

// Non-SSR render
const isBrowser = typeof window !== 'undefined';

/**
 * Read the headers during SSR pre-rendering.
 *
 * @returns headers during SSR pre-rendering, or `null` if not in SSR (e.g. browser).
 */
const useSSRHeaders = (): ReadonlyHeaders | null => {
  if (isBrowser) {
    return null;
  }
  const getHeaders = async () => {
    const { headers } = await import('next/headers');
    return await headers();
  };
  return use(getHeaders());
};

/**
 * Read cookies during SSR pre-rendering.
 *
 * @returns cookies during SSR pre-rendering, or `null` if not in SSR (e.g. browser).
 */
const useSSRCookies = (): ReadonlyRequestCookies | null => {
  if (isBrowser) {
    return null;
  }
  const getCookies = async () => {
    const { cookies } = await import('next/headers');
    return await cookies();
  };
  return use(getCookies());
};

// In SSR, we need to be able to access the session cookie to authenticate requests.
// https://github.com/vercel/next.js/discussions/59303
// https://github.com/vercel/next.js/discussions/56717
// https://github.com/apollographql/apollo-client-nextjs/issues/85
// https://www.npmjs.com/package/ssr-only-secrets
// https://stackoverflow.com/questions/54870049/accessing-cookies-from-react-ssr
// https://stackoverflow.com/questions/76595420/how-to-use-cookies-in-client-components-in-next-js-13-and-read-them-on-initial-s
export const useForwardingRequestHeaders = (internalUserAgent: string): HeadersInit | null => {
  const headers = useSSRHeaders();
  const cookies = useSSRCookies()?.toString();
  return useMemo(
    (): HeadersInit | null => getForwardingRequestHeaders(internalUserAgent, headers, cookies),
    [internalUserAgent, headers, cookies]
  );
};
