import '@fidant-io/util/symbol-dispose-shim';
/**
 * A class that measures the time it takes to execute a block of code.
 * The "onDone" callback is called when the block is exited.
 *
 * @example
 * ```ts
 * async function foo() {
 *   using timer = new BlockTimer(dt => console.log(`Block took ${dt}ms`));
 *   // … do stuff
 * }
 * ```
 */
export class BlockTimer {
    onDone;
    clock$ms;
    #startTime;
    /**
     * @param onDone a callback called on disposal with the time elapsed in milliseconds
     * @param [clock$ms] the source of time, in millseconds, defaults to `performance.now()`
     */
    constructor(onDone, clock$ms = () => performance.now()) {
        this.onDone = onDone;
        this.clock$ms = clock$ms;
        this.#startTime = clock$ms();
    }
    // other methods
    [Symbol.dispose]() {
        const endTime = this.clock$ms();
        this.onDone(endTime - this.#startTime);
    }
}
