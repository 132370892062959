import {
  AsyncLoggingScope as ASL,
  configure as configureLogging,
  getLogger as getLogger_,
  logContext as logContext_,
  LogLevels as LogLevels_,
} from '@fidant-io/logger';

// https://github.com/vercel/next.js/discussions/26427
// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace globalThis {
  let FIDANT_LOGGING:
    | Pick<typeof import('@fidant-io/logger'), 'AsyncLoggingScope' | 'getLogger' | 'logContext' | 'LogLevels'>
    | undefined;
}

if (!globalThis.FIDANT_LOGGING) {
  configureLogging();
  globalThis.FIDANT_LOGGING = {
    AsyncLoggingScope: ASL,
    getLogger: getLogger_,
    logContext: logContext_,
    LogLevels: LogLevels_,
  };
}

export type { Logger, LoggerContext, LogLevel } from '@fidant-io/logger';
export const { AsyncLoggingScope, getLogger, logContext, LogLevels } = globalThis.FIDANT_LOGGING;
