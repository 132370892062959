import { fetchExchange } from '@urql/core';
import { authExchange, hasAuthLikeError } from '../auth.js';
// https://commerce.nearform.com/open-source/urql/docs/advanced/authentication
export const remoteAuthConfigurator = (auth) => {
    let credentials = null;
    return {
        willAuthError: _op => credentials == null,
        // Called on first operation; or if we return willAuthError for an operation before executing, or didAuthError after.
        async refreshAuth() {
            credentials = await auth();
        },
        // Called for every operation.
        addAuthToOperation: op => {
            if (!credentials) {
                return op;
            }
            const fetchOptions = typeof op.context.fetchOptions === 'function' ? op.context.fetchOptions() : op.context.fetchOptions || {};
            const headers = new Headers(fetchOptions.headers);
            if (credentials.headers) {
                const credHeaders = new Headers(credentials.headers);
                credHeaders.forEach((value, key) => void headers.set(key, value));
            }
            op.context.fetchOptions = {
                credentials: 'include', // or 'same-origin'?
                mode: 'cors', // or 'same-origin'?
                ...fetchOptions,
                headers,
            };
            return op;
            // Beware: this copies the operation, it does not mutate it!
            // return util.appendHeaders(op, {
            //   ...(credentials.headers ? Object.fromEntries(credentials.headers.entries()) : {}),
            //   ...(credentials.token ? { Authorization: `Bearer ${credentials.token}` } : {}),
            // });
        },
        didAuthError: (error, _op) => [401, 403].includes(error.response?.status ?? null) ||
            hasAuthLikeError(error),
    };
};
// TODO: @urql/exchange-persisted-fetch
export const getBackend = ({ auth, exchanges, ...opts }) => ({
    ...opts,
    exchanges: [...(exchanges ?? []), authExchange(remoteAuthConfigurator(auth)), fetchExchange],
});
