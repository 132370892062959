import '@fidant-io/util/symbol-dispose-shim';
// In order to help "getLogger()" return the same instance on each invocation, we keep a weak ref on winston logger or logger config object.
// Instance identity is not really a requirement—the loggers are immutable—but it's nice to have.
export const ADAPTED_LOGGER_REF_KEY = Symbol.for('@fidant-io/logger#adapter-ref-key');
// NOTE: this is a custom symbol.
export const LOGGER_NAME = Symbol.for('@fidant-io/logger#label');
// NOTE: these are runtime-identical to the symbols declared in winston -> logform -> triple-beam,
// and so behave as correctly with respect to interactions with the winston library.
// However, without depending on that sub-library we can't declare that they're the same.
// Technically the "TransformableInfo" interface shows up as having two "LEVEL" symbols, etc.
//
// This is also why TS doesn't complain that we're changing the types of those members in our winston type definitions from `any` to actual types.
export const LEVEL = Symbol.for('level');
export const MESSAGE = Symbol.for('message');
export const SPLAT = Symbol.for('splat');
