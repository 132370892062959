'use client';

import '@/shims';

import type { AnyVariables, Client as UrqlClient, UseQueryArgs, UseQueryResponse } from '@urql/next';
import {
  ssrExchange,
  UrqlProvider,
  useQuery as useOriginalUrqlNextUseQuery,
  useClient as useUrqlClient,
} from '@urql/next';
import { useEffect, useMemo, useReducer, useRef } from 'react';
import type { SetOptional } from 'type-fest';

import type { FidantSessionInfo } from '@/auth/client';
import { useSession, useSessionInfo } from '@/auth/client';
import type { FidantClientSession } from '@/auth/types';
import type { SuspenseOrAuthBoundaryProps } from '@/components/SuspenseOrAuthBoundary';
import { SuspenseOrAuthBoundary } from '@/components/SuspenseOrAuthBoundary';
import { useCustomMemo } from '@/components/useCustomMemo';
import { useJSONEqualValue } from '@/components/useJSONEqualValue';
import { useLogger } from '@/log/client';
import type { FidantAPIClient, RemoteBackendRequestAuthenticator } from '@fidant-io/api-client';
import { AuthenticationError, createConfiguredClient, getBackend } from '@fidant-io/api-client';
import type { PromiseWithResolversAndResult } from '@fidant-io/util/promise';
import { promiseWithResolversAndResult } from '@fidant-io/util/promise';

import { useForwardingRequestHeaders } from './headers.client';

// Non-SSR render
const isBrowser = typeof window !== 'undefined';

type AuthDeferred = PromiseWithResolversAndResult<FidantClientSession>;
type AuthDeferredState = { deferred: AuthDeferred; headers: HeadersInit | null; controller: AbortController };

const newAuthStatusDeferred = (session: FidantSessionInfo, headers: HeadersInit | null) => {
  const value = promiseWithResolversAndResult<FidantClientSession>();
  // Avoid potential "unhandled promise rejection" warnings
  value.promise.then(
    () => {},
    () => {}
  );
  const controller = new AbortController();
  function onAbort(this: AbortSignal) {
    value.reject(this.reason || new DOMException('Aborted', 'AbortError'));
  }
  controller.signal.addEventListener('abort', onAbort, { once: true });
  const state = { deferred: value, controller, headers };
  // Synchronously resolve the promise if the session is already authenticated.
  handleAuthStatusUpdate(session, state);
  return state;
};

const handleAuthStatusUpdate = (session: FidantSessionInfo, state: AuthDeferredState) => {
  if (state.deferred.result) {
    // console.log('SKIPPING RESOLVED AUTH', session);
  } else if (session.status === 'loading') {
    // console.log('STILL LOADING AUTH', session);
  } else if (session.status === 'authenticated') {
    // console.log('RESOLVING AUTH PROMISE', session);
    state.deferred.resolve(session.data);
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (session.status === 'unauthenticated') {
    // console.log('FAILING AUTH PROMISE', session);
    // TODO: instead of rejecting, continue waiting and handle the re-auth attempt elsewhere?
    state.deferred.reject(new AuthenticationError());
  }
};

const useSessionRequestAuthenticator = (session: FidantSessionInfo) => {
  const headers = useForwardingRequestHeaders('node/fidant.io web-ui-ssr');

  const ref = useRef<AuthDeferredState>();
  ref.current ??= newAuthStatusDeferred(session, headers);

  useEffect(() => {
    if (!ref.current) {
      // Should never happen!
      ref.current = newAuthStatusDeferred(session, headers);
    } else {
      ref.current.headers = headers ?? ref.current.headers;
      handleAuthStatusUpdate(session, ref.current);
    }
    const authDeferred = ref.current;
    return () => {
      authDeferred.controller.abort();
      if (ref.current === authDeferred) {
        ref.current = undefined;
      }
    };
  }, [session, headers]);

  const authFuncRef = useRef<RemoteBackendRequestAuthenticator>();
  authFuncRef.current ??= async () => {
    if (!ref.current) {
      console.error('CLIENT: Component unmounted, but not canceled', { isBrowser });
      return null;
    }
    const pwr = ref.current.deferred;
    const signal = ref.current.controller.signal;
    let value: FidantClientSession;
    try {
      signal.throwIfAborted();
      if (!pwr.result) {
        value = await pwr.promise;
      } else if (pwr.result.status === 'fulfilled') {
        // NOTE: avoiding `await` when possible is necessary for the SSR serialization to work.
        value = pwr.result.value;
      } else {
        throw pwr.result.reason;
      }
    } catch (e) {
      if (signal.aborted) {
        return null;
      }
      throw e;
    }
    if (!isBrowser && value.expires != 'TODO:' && ref.current.headers) {
      return { headers: ref.current.headers };
    } else {
      return {};
    }
  };
  return authFuncRef.current;
};

interface InnerProps {
  url: string;
}

function FidantAPIClientProviderInner({ url, children }: React.PropsWithChildren<InnerProps>) {
  const session = useSessionInfo();

  const logger = useLogger(
    '@fidant-io/gql',
    process.env.NODE_ENV === 'production' ? (isBrowser ? null : 'info') : 'debug',
    isBrowser ? {} : { ssr: true }
  );

  // This is bound to a ref because in dev mode, I was seeing infinite re-renders when I just had useMemo,
  // even after verifying that all instances were identical. This could have been related to a faulty "suspense"
  // setting, or the re-use of the ssr exchange with the (RSC) server-only client.
  // Note that this was using React 17 or 18; in React 19, strict mode re-renders return the first memoized value,
  // which should fix this issue.
  // https://react.dev/blog/2024/04/25/react-19-upgrade-guide#strict-mode-improvements
  const authFunc = useSessionRequestAuthenticator(session);

  // Each ssr exchange records any urql requests/responses that occur during rendering.
  // The hydration data is appended to an array on `window[Symbol.for("urql_transport")]`,
  // and hydrate in order and dehydrate in order.
  // Be careful that this *must* not be shared between users; don't put it in a global.
  const ssr = useMemo(() => ssrExchange({ isClient: isBrowser, includeExtensions: true }), []);

  const client = useCustomMemo(
    (url, logger, ssr, authFunc) =>
      createConfiguredClient({
        suspense: true,
        scalars: 'rsc',
        ...(logger ? { logger } : {}),
        backend: getBackend({
          url,
          auth: authFunc,
          exchanges: [ssr],
        }),
      }),
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (a, b) => a === b || (a.length === b.length && a.every((v, i) => v === b[i])),
    [url, logger, ssr, authFunc]
  );

  return (
    <UrqlProvider client={client as UrqlClient} ssr={ssr}>
      {/* Boundary for children *using* the client */}
      <SuspenseOrAuthBoundary name="with-api-client" resetKeys={[client, session.status]}>
        <HystericalAuthGate>{children}</HystericalAuthGate>
      </SuspenseOrAuthBoundary>
    </UrqlProvider>
  );
}

/**
 * Session state can be loading transiently. Only render children if we are authenticated, or were before.
 * This is called "hysterical" because it represents hysteresis in the auth state.
 */
const HystericalAuthGate = ({ children }: React.PropsWithChildren) => {
  const { status } = useSession();
  const [isPermissible, dispatchAuthState] = useReducer(
    (prevState: boolean, authState: typeof status) => {
      if (authState === 'authenticated') {
        return true;
      } else if (authState === 'unauthenticated') {
        return false;
      } else {
        return prevState;
      }
    },
    status,
    n => n === 'authenticated'
  );
  useEffect(() => void dispatchAuthState(status), [status]);
  return isPermissible ? children : null;
};

export interface Props extends SetOptional<SuspenseOrAuthBoundaryProps, 'name'> {
  apiUrl: string;
}

export function FidantAPIClientProvider({
  apiUrl,
  name = 'api-client',
  children,
  ...props
}: React.PropsWithChildren<Props>) {
  // Boundary for auth-like errors, or those propagated from children.
  return (
    <SuspenseOrAuthBoundary name={name} {...props}>
      <FidantAPIClientProviderInner url={apiUrl}>{children}</FidantAPIClientProviderInner>
    </SuspenseOrAuthBoundary>
  );
}

export const useGraphqlClient = () => useUrqlClient() as FidantAPIClient;

export { useMutation, useSubscription } from '@urql/next';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useQuery<Data = any, Variables extends AnyVariables = AnyVariables>(
  args: UseQueryArgs<Variables, Data>
): UseQueryResponse<Data, Variables | undefined> {
  const query = useJSONEqualValue(args.query);
  const variables = useJSONEqualValue((args.variables ?? {}) as Variables);
  // This is necessary to prevent infinite re-renders from dirty `context` objects.
  const context = useJSONEqualValue(args.context ?? {});
  args = useMemo(
    () => ({
      ...(args.pause != null && { pause: args.pause }),
      ...(args.requestPolicy != null && { requestPolicy: args.requestPolicy }),
      context,
      query,
      variables,
    }),
    [args.pause, args.requestPolicy, context, query, variables]
  );
  return useOriginalUrqlNextUseQuery(args);
}
