import { LogLevels } from './interface.js';
export const LEVEL_NOT_CONFIGURED = 'BAD_LEVEL';
export function getLogLevel(default_, current, override) {
    return override ?? (current && LogLevels.includes(current) ? current : default_) ?? LEVEL_NOT_CONFIGURED;
}
export class LoggerModule {
    registry;
    configurator;
    constructor(registry, configurator) {
        this.registry = registry;
        this.configurator = configurator;
    }
    configure(options = {}) {
        const { loggers: perLoggerOptions = {} } = options;
        const registry = this.registry;
        if (registry.configuration) {
            throw new Error('Logging has already been configured');
        }
        registry.configure(options);
        const knownIds = new Set([...registry.loggers.keys(), ...Object.keys(perLoggerOptions)]);
        for (const id of knownIds) {
            // For every existing or specialized logger, ensure it's initialized with the new defaults.
            registry.define(id, perLoggerOptions[id]);
        }
    }
    getLogger(name, options = {}) {
        return this.registry.get(name, options);
    }
    getKnownLoggerConfig() {
        return Array.from(this.registry.loggers.entries(), ([id, { level, context: ctx }]) => ({
            id,
            level,
            ctx,
        })).sort(({ id: a }, { id: b }) => a.localeCompare(b));
    }
}
